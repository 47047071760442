<template>
  <LadImage
    :src="image.src"
    :srcset="image.srcset"
    :min-height="minHeight"
    :max-width="maxWidth"
    :preloading="preloading"
    :include-all-sources="includeAllSources"
    :lazy-loading="lazyLoading"
    :alt="alt"
    :title="title"
    class="image--cover"
  />
</template>

<script>
export default {
  name: 'LadCoverMedia',
  components: {
    LadImage: () =>
      import(
        /* webpackChunkName: "lad-image" */ '@/components/frontends/Image/index'
      ),
  },
  props: {
    maxWidth: { type: Number, default: 0 },
    // eslint-disable-next-line vue/require-prop-type-constructor
    minHeight: { type: String | Number, default: 'auto' },
    includeAllSources: { type: Boolean, default: false },
    // eslint-disable-next-line vue/no-boolean-default
    lazyLoading: { type: Boolean, default: true },
    preloading: { type: Boolean, default: false },
    alt: { type: String, default: '' },
    title: { type: String, default: '' },
  },
}
</script>
