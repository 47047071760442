<script>
import LadCoverMedia from './media'

export default {
  name: 'LadStaticCoverImage',
  extends: LadCoverMedia,
  props: {
    src: { type: String, default: '' },
  },
  computed: {
    image() {
      return { src: this.src }
    },
  },
}
</script>
